

button
{
  position:absolute;
  top:0px;
  left:0px;
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

h1 {
  text-align: center;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-size: 27px;
  color: #444444;
  font-weight: 700;
}
h1 span {
  font-weight: 400;
}

.container {
  background-color:#47e4dd;
  background: rgba(54,219,214,1);
background: -moz-linear-gradient(top, rgba(54,219,214,1) 0%, rgba(236,231,199,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(54,219,214,1)), color-stop(100%, rgba(236,231,199,1)));
background: -webkit-linear-gradient(top, rgba(54,219,214,1) 0%, rgba(236,231,199,1) 100%);
background: -o-linear-gradient(top, rgba(54,219,214,1) 0%, rgba(236,231,199,1) 100%);
background: -ms-linear-gradient(top, rgba(54,219,214,1) 0%, rgba(236,231,199,1) 100%);
background: linear-gradient(to bottom, rgba(54,219,214,1) 0%, rgba(236,231,199,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#36dbd6', endColorstr='#ece7c7', GradientType=0 );
  overflow:hidden;
  margin:0px;
    font-family: Arial, sans-serif;


  width: 735px;
  margin: 50px auto;
  background: whitesmoke;
  padding: 25px;
}

.button {
  border: 0 none;
  border-radius: 2px 2px 2px 2px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Arial,sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 0;
  margin-top: 10px;
  padding: 7px 10px;
  text-transform: none;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  width: 16.795%;
  /* auto */
  text-align: center;
  /* DELETE WHEN WIDTH AUTO */
}
.button.red {
  background: none repeat scroll 0 0 #E0645C;
  color: #FFFFFF;
}
.button.red:hover {
  background: none repeat scroll 0 0 #999999;
  color: #FFFFFF;
}
menu
{
  position:absolute;
  left:0px;
  top:0px;
}

label
{
  font-size:12px;
}



.mainContainer{

  background-image: url("../../assets/images/skybg.jpeg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}